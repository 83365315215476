import React from 'react';

export default function ServicesSectionFeatures3() {
    return (
        <React.Fragment>
            <>
                <section className="relative pt-20 pb-20 lg:pb-32 overflow-hidden">
  <img className="absolute top-0 right-0" src="https://static.shuffle.dev/uploads/files/d5/d565b75b2e1e4116bea1270495296850d723e2d0/star-left.png" alt />
  <div className="relative container px-4 mx-auto">
    <div className="max-w-md lg:max-w-7xl mx-auto">
      <div className="flex flex-wrap -mx-4 mb-18 items-center">
        <div className="w-full lg:w-2/3 xl:w-1/2 px-4 mb-12 lg:mb-0">
          <div className="max-w-lg">
            <span className="inline-block py-1 px-3 mb-4 text-xs font-semibold rounded-full text-gray-900 bg-green-50">FEATURES</span>
            <h1 className="font-heading text-5xl xs:text-6xl md:text-7xl font-bold text-gray-900">
              <span>Refrigerated Trailers</span>
              <span className="font-serif italic">Available</span>
            </h1>
          </div>
        </div>
        <div className="w-full lg:w-1/3 xl:w-1/2 px-4">
          <div className="max-w-lg lg:ml-auto">
            <p className="text-lg text-gray-500">You can always give us a call so that our professional team can find the best solution for you. Reefer, Dry Vans, Flat Beds are waiting to haul your product.</p>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap -mx-4">
        <div className="w-full lg:w-1/3 px-4 mb-12 lg:mb-0">
          <div>
            <img className="block mb-6 w-full h-100 rounded-3xl" src="https://static.shuffle.dev/uploads/files/d5/d565b75b2e1e4116bea1270495296850d723e2d0/reefer.png" alt />
            <div>
              <h3 className="text-3xl font-semibold mb-5">Reefers</h3>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-1/3 px-4 mb-12 lg:mb-0">
          <div>
            <img className="block mb-6 w-full h-100 rounded-3xl" src="https://static.shuffle.dev/uploads/files/d5/d565b75b2e1e4116bea1270495296850d723e2d0/dry.webp" alt />
            <div>
              <h3 className="text-3xl font-semibold mb-5">Dry Vans</h3>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-1/3 px-4">
          <div>
            <img className="block mb-6 w-full h-100 rounded-3xl" src="https://static.shuffle.dev/uploads/files/d5/d565b75b2e1e4116bea1270495296850d723e2d0/DALL-E-2024-08-20-00-15-29-A-rear-view-of-a-flatbed-semi-truck-trailer-with-green-accents-on-a-plain-white-background-The-trailer-is-attached-to-a-semi-truck-and-features-a-sim.webp" alt />
            <div>
              <h3 className="text-3xl font-semibold mb-5">Flatbed</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

