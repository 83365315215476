import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ServicesSectionNavigations2 from '../components/navigations/ServicesSectionNavigations2';
import ServicesSectionFeatures3 from '../components/features/ServicesSectionFeatures3';
import ServicesSectionFooters4 from '../components/footers/ServicesSectionFooters4';
import Navbar from '../components/navbar';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Services() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      {/* <Navbar /> */}
      <ServicesSectionFeatures3 />
      <ServicesSectionFooters4 />
    </React.Fragment>
  );
}

