import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import AboutSectionContacts2 from '../components/contacts/AboutSectionContacts2';
import AboutSectionFooters4 from '../components/footers/AboutSectionFooters4';
import Navbar from '../components/navbar';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function About() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      {/* <Navbar /> */}
      <AboutSectionContacts2 />
      <AboutSectionFooters4 />
    </React.Fragment>
  );
}

