import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ApplicationSectionFaq1 from '../components/faq/ApplicationSectionFaq1';
import Navbar from '../components/navbar';

const meta = {
  title: 'Driver\u0020Application',
  meta: [
    {
      name: 'keywords',
      content:
        'freight\u0020management,\u0020freight\u0020companies,\u0020carrier\u0020company,\u0020carriers,\u0020trucking,\u0020trucks,\u0020loads,\u0020orders,\u0020logistic,\u0020logistics,\u0020FTL,\u0020ftl,\u0020ltl,\u0020multimodal,\u0020DAT,\u0020loadboard',
    },
  ],
  link: [],
  style: [],
  script: [],
};

export default function Application() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      {/* <Navbar /> */}
      <ApplicationSectionFaq1 />
    </React.Fragment>
  );
}

