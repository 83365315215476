import React from "react";

export default function ApplicationSectionFaq1() {
  return (
    <React.Fragment>
      <>
        <section className="relative py-20 overflow-hidden bg-gray-50">
          {" "}
          <img
            className="absolute top-0 left-0 mt-44"
            src="saturn-assets/images/faq/light-blue-left.png"
            alt
          />{" "}
          <img
            className="absolute top-0 right-0 mt-10"
            src="saturn-assets/images/faq/star-right.svg"
            alt
          />{" "}
          <div className="relative container px-4 mx-auto">
            {" "}
            <div className="max-w-7xl mx-auto">
              {" "}
              <div className="text-center mb-12">
                {" "}
                <span className="inline-block py-1 px-3 mb-4 text-xs font-semibold rounded-full text-gray-900 bg-green-50">
                  FREQUENTLY ASK QUESTION
                </span>{" "}
                <h1 className="font-heading text-5xl xs:text-6xl md:text-7xl font-bold">
                  {" "}
                  <span>Driver</span>{" "}
                  <span className="font-serif italic">application</span>{" "}
                </h1>{" "}
              </div>{" "}
              <div className="flex flex-wrap -mx-4 h-full">
                {" "}
                <iframe
                  id="JotFormIFrame-242325389782163"
                  title="Darwin Express Driver Application Form"
                  onload="window.parent.scrollTo(0,0)"
                  allowTransparency="true"
                  allow="geolocation; microphone; camera; fullscreen"
                  src="https://form.jotform.com/242325389782163"
                  frameBorder={0}
                  style={{
                    minWidth: "100%",
                    maxWidth: "100%",
                    height: 800,
                    border: "none",
                  }}
                  scrolling="yes"
                ></iframe>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}
