import React from 'react';
import {
  Routes,
  Route
} from 'react-router-dom';

import IndexPage from './pages/Index.js';
import AboutPage from './pages/About.js';
import ApplicationPage from './pages/Application.js';
import ServicesPage from './pages/Services.js';
import Navbar from './components/navbar.jsx';

function App() {
  return (
    <>
    <Navbar />
    <Routes>
      <Route path="/" element={<IndexPage />} />
         
      <Route path="/about" element={<AboutPage />} />
         
      <Route path="/application" element={<ApplicationPage />} />
         
      <Route path="/services" element={<ServicesPage />} />
         
    </Routes>
      </>
  );
}

export default App;